import React, { memo } from "react";
import { Themes, ThemeContext, ToastContainer, Slide } from "@clintonelec/react-storybook";
import TitleBar from "Components/TitleBar";
import HostnameTable from "Components/HostnameTable";
import UserSettings from "Components/UserSettings";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import LoginPage from "Components/Auth/LoginPage";
import ForgotPasswordPage from "Components/Auth/ForgotPasswordPage";
import VerifyEmailPage from "Components/Auth/VerifyEmailPage";
import RegisterPage from "Components/Auth/RegisterPage";
import { toggleFeatureRenderer as toggleFeature } from "Components/Features";
import { Features } from "Data/Utils/Features";
import { useAppSelector } from "Data/Redux/Store";
import { getActiveToken, getActiveUser } from "Data/Selectors/User";

const App = () => {
	const user = useAppSelector(getActiveUser);
	const token = useAppSelector(getActiveToken);

	const renderBaseLayout = () => {
		if (!(user && token)) {
			return <Navigate to="/login" />;
		}

		return (
			<div className="content-area">
				<TitleBar />
				<Outlet />
			</div>
		);
	};

	const renderRegisterRoute = () => {
		if (!toggleFeature(Features.REGISTER, true, false)) {
			return;
		}

		return (
			<Route path="/register" element={ <RegisterPage /> } />
		);
	};

	const compositeClass = `app-window app-window-${ Themes.LIGHT }`;

	return (
		<ThemeContext.Provider value={ Themes.LIGHT }>
			<div className={ compositeClass }>
				<Routes>
					<Route path="/login" element={ <LoginPage /> } />
					{ renderRegisterRoute() }
					<Route path="/reset-password/:token" element={ <ForgotPasswordPage /> } />
					<Route path="/verify-email/:id/:emailHash" element={ <VerifyEmailPage /> } />
					<Route path="/" element={ renderBaseLayout() }>
						<Route index element={ <HostnameTable /> } />
						<Route path="/settings" element={ <UserSettings /> } />
					</Route>
				</Routes>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={ 4500 }
				hideProgressBar
				newestOnTop={ false }
				closeOnClick
				rtl={ false }
				pauseOnFocusLoss
				draggable
				pauseOnHover
				transition={ Slide }
				className="cec-toast"
				limit={ 10 }
				style={ { width: 350 } }
				theme={ Themes.LIGHT }
			/>
		</ThemeContext.Provider>
	);
};

export default memo(App);
