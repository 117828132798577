import React, { FormEvent, memo, useRef, useState } from "react";
import { Button, ButtonTypes, IconSize, Input } from "@clintonelec/react-storybook";
import "Components/Auth/LoginPage/LoginPage.less";
import { loginUserAsyncAction } from "Data/Actions/User";
import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationType, notify } from "src/Notifications";
import { useAppDispatch } from "Data/Redux/Store";
import { forgotPassword } from "Data/API/Auth";
import { Link, useNavigate } from "react-router-dom";
import { toggleFeatureRenderer } from "Components/Features";
import { Features } from "Data/Utils/Features";
import AuthPage from "Components/Auth/AuthPage";

interface ILoginFormFields extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

interface IForgotPasswordFormFields extends HTMLFormElement {
	email: HTMLInputElement;
}

const LoginPage = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const loginUser = (email: string, password: string) => dispatch(loginUserAsyncAction({ email, password }));
	const formRef = useRef<HTMLFormElement>(null);
	const [ submitted, updateSubmitted ] = useState(false);
	const [ forgotPasswordVisible, setForgotPasswordVisible ] = useState(false);

	const toggleForgotPassword = () => {
		setForgotPasswordVisible(!forgotPasswordVisible);
	};

	const handleLoginSubmit = (event: FormEvent<ILoginFormFields>) => {
		event.preventDefault();

		if (!loginUser) {
			return;
		}

		updateSubmitted(true);

		if (formRef.current.checkValidity()) {
			loginUser(event?.currentTarget?.email?.value, event?.currentTarget?.password?.value)
				.then((result: PayloadAction<null, null, null, string>) => {
					if (result.error) {
						notify(NotificationType.ERROR, "Login Error", "The user credentials were incorrect");
					} else {
						navigate("/");
					}
				});
		}
	};

	const handleSubmitForgotPassword = (event: FormEvent<IForgotPasswordFormFields>) => {
		event.preventDefault();

		updateSubmitted(true);

		if (formRef.current.checkValidity()) {
			forgotPassword({ email: event?.currentTarget?.email?.value })
				.then(() => {
					notify(NotificationType.SUCCESS, "Password reset email sent.");
					updateSubmitted(false);
					toggleForgotPassword();
				})
				.catch(() => {
					notify(NotificationType.ERROR, "Too many attempts.");
				});
		}
	};

	const loginForm = (
		<form onSubmit={ handleLoginSubmit } ref={ formRef } noValidate>
			<label>Email</label>
			<Input
				name="email"
				wrapClassName="login-input-email"
				required
				noValidate={ !submitted }
				validityMessage="Email is required"
			/>
			<label>Password</label>
			<Input
				name="password"
				wrapClassName="login-input"
				password
				required
				noValidate={ !submitted }
				validityMessage="Password is required"
			/>
			<Button
				id="login-button"
				className="login-button"
				type={ ButtonTypes.PRIMARY }
				htmlType="submit"
				icon={ {
					name: "sign-in",
					size: IconSize.SMALLER
				} }
			>
				Sign In
			</Button>
		</form>
	);

	const forgotPasswordForm = (
		<form ref={ formRef } noValidate onSubmit={ handleSubmitForgotPassword }>
			<label>Email</label>
			<Input
				name="email"
				required
				noValidate={ !submitted }
				validityMessage={ submitted ? "Email is required" : undefined }
			/>
			<Button
				id="login-button"
				className="login-button"
				type={ ButtonTypes.PRIMARY }
				htmlType="submit"
				icon={ {
					name: "envelope",
					size: IconSize.SMALLER
				} }
			>
				Send Recovery Link
			</Button>
		</form>
	);

	const renderRegisterLink = () => {
		if (!toggleFeatureRenderer(Features.REGISTER, true, false) || forgotPasswordVisible) {
			return;
		}

		return (
			<Link className="form-link" to="/register">Don&apos;t have an account?</Link>
		);
	};

	const renderPageAction = () => {
		if (forgotPasswordVisible) {
			return (
				<div className="form-link" onClick={ toggleForgotPassword }>Return to Log in page</div>
			);
		}

		return (
			<div className="form-link" onClick={ toggleForgotPassword }>Forgot Your Password?</div>
		);
	};

	const formFooter = (
		<div className="form-footer">
			{ renderPageAction() }
			{ renderRegisterLink() }
		</div>
	);

	const pageContent = forgotPasswordVisible ? forgotPasswordForm : loginForm;
	const loginContent = (
		<div className="login-page">
			<div className="logo" />
			{ pageContent }
			{ formFooter }
		</div>
	);

	return <AuthPage form={ loginContent } />;
};

export default memo(LoginPage);
