import React, { ReactNode, memo, useEffect } from "react";
import "Components/Auth/AuthPage/AuthPage.less";
import PolicyModal from "Components/Global/PolicyModal";
import { PolicyType } from "Interfaces";
import { useAppSelector } from "Data/Redux/Store";
import { getActiveToken, getActiveUser } from "Data/Selectors/User";
import { useNavigate } from "react-router-dom";

interface IAuthPageProps {
	form: ReactNode;
}

const AuthPage = (props: IAuthPageProps) => {
	const { form } = props;
	const navigate = useNavigate();
	const user = useAppSelector(getActiveUser);
	const token = useAppSelector(getActiveToken);
	const copyrightYears = `2017-${ new Date().getFullYear() }`;

	useEffect(() => {
		if (user && token) {
			navigate("/");
		}
	}, [ user, token, navigate ]);

	return (
		<div className="auth-page">
			<div className="center-content">
				<div className="login-content">
					{ form }
				</div>
			</div>
			<div className="login-footer">
				<div className="footer-row footer-privacy">
					<PolicyModal policy={ PolicyType.TERMS_AND_CONDITIONS }>Terms & Conditions</PolicyModal>
					<PolicyModal policy={ PolicyType.PRIVACY_POLICY }>Privacy Policy</PolicyModal>
				</div>
				<div className="footer-row footer-copyright">
					&copy; { copyrightYears } Clinton Electronics.
				</div>
			</div>
		</div>
	);
};

export default memo(AuthPage);
