import Pusher, { Channel } from "pusher-js";
import { isProduction } from "Data/Utils/System";
import { ActionCreatorWithOptionalPayload, AsyncThunk, AsyncThunkAction } from "@reduxjs/toolkit";
import { store } from "Data/Redux/Store";
import { IAppState, IPusherEvent, IWithApiData } from "Interfaces";
import { IIndexSignature } from "@clintonelec/typescriptutils";
import { getActiveToken, getActiveUser, selectPusherKey } from "Data/Selectors/User";
import {
	createHostnameLocalAction, deleteHostnameLocalAction, updateHostnameLocalAction
} from "Data/Actions/Hostnames";
import { updateAccountLocalAction } from "Data/Actions/User";

const deleteWrapper = <T, V>(actionCreator: AsyncThunk<T, string, V>) => {
	return (notification: IWithApiData<unknown>) => {
		return actionCreator(notification.data.id);
	};
};

type EventAction = AsyncThunk<unknown, unknown, unknown>
	| ((data: IWithApiData<unknown>) => AsyncThunkAction<unknown, unknown, unknown>)
	| ActionCreatorWithOptionalPayload<unknown>;

interface IEventBindings {
	[ type: string ]: {
		[ event: string ]: {
			action?: EventAction;
			callback?: () => void;
			channel?: IIndexSignature<EventAction>;
		}
	}
}

const EVENT_BINDINGS: IEventBindings = {
	hostname: {
		created: {
			action: createHostnameLocalAction
		},
		updated: {
			action: updateHostnameLocalAction
		},
		deleted: {
			action: deleteWrapper(deleteHostnameLocalAction)
		}
	},
	user: {
		updated: {
			action: updateAccountLocalAction
		}
	}
};

export class PusherConnection {
	pusherConnection: Pusher;
	userChannel: Channel;

	initializeConnection = () => {
		this.connectPusher();
		this.connectUserChannel();
	};

	connectPusher = () => {
		const state: IAppState = store.getState();
		const user = getActiveUser(state);
		const token = getActiveToken(state);
		const pusherKey = selectPusherKey(state);
		const authEndpoint = isProduction
			? "https://ddns.clintonelectronics.com/broadcasting/auth"
			: "https://develop.ddns.clintonelectronics.com/broadcasting/auth";

		if (pusherKey && user && !this.pusherConnection) {
			this.pusherConnection = new Pusher(pusherKey, {
				authEndpoint,
				auth: {
					headers: {
						"Authorization": `Bearer ${ token }`
					}
				},
				disableStats: true,
				enabledTransports: [ "ws", "wss" ],
				forceTLS: true,
				wsHost: "conloquium.clintonconnect.com",
				cluster: "mt1"
			});
		}
	};

	connectUserChannel = (reset?: boolean) => {
		const state: IAppState = store.getState();
		const user = getActiveUser(state);
		const channelName = `private-user-${ user.id }`;

		if (!this.userChannel || this.userChannel.name !== channelName) {
			this.pusherConnection.allChannels().forEach((channel: Channel) => {
				if (channel.name.indexOf(channelName) >= 0) {
					this.pusherConnection.unsubscribe(channel.name);
				}
			});

			this.userChannel = this.pusherConnection.subscribe(channelName);
		}

		if (!this.userChannel.subscribed || reset) {
			this.userChannel.unbind_global();
			this.userChannel.bind_global(this.createPusherHandler(channelName));
		}
	};

	createPusherHandler = (channel: string) => {
		const [ , channelType ] = channel.split("-", 2);

		return (eventType: string, notification: IPusherEvent) => {
			if (!notification || !channelType) {
				return;
			}

			const eventBinding = EVENT_BINDINGS?.[ notification.type ]?.[ notification.event ];
			const actionCreator = eventBinding?.channel?.[ channelType ] ?? eventBinding?.action;

			if (actionCreator) {
				const { callback } = eventBinding;

				notification.entities.forEach((id: string) => {
					if (notification.batch) {
						notification.data.forEach(data => {
							Promise.resolve(store.dispatch(actionCreator(
								{ data: { ...data } }
							))).then(callback);
						});
					} else {
						Promise.resolve(store.dispatch(actionCreator(
							{ data: { ...notification.data, id } }
						))).then(callback);
					}
				});
			}
		};
	};

	disconnect = () => {
		this.pusherConnection.allChannels().forEach((channel: Channel) => {
			this.pusherConnection.unsubscribe(channel.name);
		});

		this.pusherConnection.unbind_all();
		this.pusherConnection.disconnect();

		delete this.pusherConnection;
		delete this.userChannel;
	};
}

export const pusherConnection = new PusherConnection();
