import { Popover, PopoverPlacement, PopoverTrigger } from "@clintonelec/react-storybook";
import React, { memo } from "react";
import { getActiveUser } from "Data/Selectors/User";
import { logoutUserRemoteAction } from "Data/Actions/User";
import { clearCacheAction } from "Data/Actions/System";
import Icon, { IconSize, IconWeight } from "Components/Icon";
import "Components/TitleBarPopover/TitleBarPopover.less";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { Link, useNavigate } from "react-router-dom";

const TitleBarPopover = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector(getActiveUser);
	const logout = () => dispatch(logoutUserRemoteAction());
	const clearCache = () => dispatch(clearCacheAction());
	const navigateToSettings = () => navigate("settings");

	const handleLogout = () => {
		logout().then(clearCache);
	};

	const popoverContent = (
		<div className="title-bar-content-wrapper">
			<div className="title-bar-popover-row" onClick={ navigateToSettings }>
				<Icon
					className="edit-settings-icon"
					fixedWidth
					name="pencil"
					iconWeight={ IconWeight.LIGHT }
				/>
				<Link to="settings">Edit User Settings</Link>
			</div>
			<div className="title-bar-popover-row" onClick={ handleLogout }>
				<Icon
					className="sign-out-icon"
					fixedWidth
					name="sign-out"
					iconWeight={ IconWeight.LIGHT }
				/>
				<span>Log Out</span>
			</div>
		</div>
	);

	return(
		<div className="title-bar-popover-wrap">
			<Popover
				className="title-bar-popover"
				content={ popoverContent }
				trigger={ PopoverTrigger.CLICK }
				placement={ PopoverPlacement.BOTTOM_END }
				arrow={ false }
			>
				<Icon
					className="user-name-title-icon"
					name="user"
					fixedWidth
					size={ IconSize.SMALLER }
				/>
				<span>{ user?.name }</span>
			</Popover>
		</div>
	);
};

export default memo(TitleBarPopover);
