import React, { FormEvent, memo, useState } from "react";
import "Components/Auth/RegisterPage/RegisterPage.less";
import { Button, ButtonTypes, IconSize, Input } from "@clintonelec/react-storybook";
import { IRegisterUserPayload } from "Interfaces";
import { useAppDispatch } from "Data/Redux/Store";
import { registerUserAction } from "Data/Actions/User";
import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationType, notify } from "src/Notifications";
import { useNavigate } from "react-router-dom";
import AuthPage from "Components/Auth/AuthPage";

interface IRegisterFormFields extends HTMLFormElement {
	email: HTMLInputElement;
	username: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

const RegisterPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [ submitted, setSubmitted ] = useState(false);
	const registerUser = (payload: IRegisterUserPayload) => dispatch(registerUserAction(payload));
	const navigateToLogin = () => navigate("/login");

	const handleRegisterSubmit = (event: FormEvent<IRegisterFormFields>) => {
		event.preventDefault();
		setSubmitted(true);

		if (event.currentTarget.checkValidity()) {
			const { email, username, password, confirmPassword } = event.currentTarget;
			const registerPayload: IRegisterUserPayload = {
				email: email.value,
				name: username.value,
				password: password.value,
				passwordConfirmation: confirmPassword.value
			};

			registerUser(registerPayload)
				.then((result: PayloadAction<null, null, null, string>) => {
					if (result.error) {
						notify(NotificationType.ERROR, "Login Error", "The user credentials were incorrect");
					} else {
						navigateToLogin();
					}
				});
		}
	};

	const registerForm = (
		<div className="register-page">
			<div className="logo" />
			<form onSubmit={ handleRegisterSubmit } noValidate>
				<label>Email</label>
				<Input
					name="email"
					wrapClassName="register-input"
					required
					noValidate={ !submitted }
					validityMessage="Email is required"
				/>
				<label>Name</label>
				<Input
					name="username"
					wrapClassName="register-input"
					required
					noValidate={ !submitted }
					validityMessage="Email is required"
				/>
				<label>Password</label>
				<Input
					name="password"
					wrapClassName="register-input"
					password
					required
					noValidate={ !submitted }
					validityMessage="Password is required"
				/>
				<label>Confirm Password</label>
				<Input
					name="confirmPassword"
					wrapClassName="register-input"
					password
					required
					noValidate={ !submitted }
					validityMessage="Confirm password is required"
				/>
				<Button
					id="register-button"
					className="register-button"
					type={ ButtonTypes.PRIMARY }
					htmlType="submit"
					icon={ {
						name: "sign-in",
						size: IconSize.SMALLER
					} }
				>
					Register
				</Button>
			</form>
			<div className="navigate-login" onClick={ navigateToLogin }>Return to Log in page</div>
		</div>
	);

	return <AuthPage form={ registerForm } />;
};

export default memo(RegisterPage);
