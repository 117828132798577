import { dom, library } from "@fortawesome/fontawesome-svg-core";
import * as light from "@fortawesome/pro-light-svg-icons";
import * as regular from "@fortawesome/pro-regular-svg-icons";
import * as solid from "@fortawesome/pro-solid-svg-icons";

// Add our solid, regular, and light icons to the library
export const icons = [
	"faSignIn", "faSignOut", "faEyeSlash", "faEye", "faTimes", "faUser", "faSearch", "faPencil", "faTrash",
	"faAngleRight", "faAngleDown", "faServer", "faInbox", "faCaretUp", "faCaretDown", "faSave", "faCircleCheck",
	"faEnvelope", "faLockKeyhole"
];

export default function initFontAwesome() {
	icons.map((icon: string) => {
		library.add(solid[icon]);
		library.add(regular[icon]);
		library.add(light[icon]);
	});

	dom.watch();
}
