import React, { memo, useState, FormEvent, Fragment } from "react";
import { Button, Input, Modal } from "@clintonelec/react-storybook";
import { useAppDispatch } from "Data/Redux/Store";
import { createHostnameAction } from "Data/Actions/Hostnames";
import { IHostname } from "Interfaces";
import "Components/HostnameModal/HostnameModal.less";
import Icon, { IconWeight } from "Components/Icon";
import { NotificationType, notify } from "src/Notifications";

interface IHostnameFormElement extends HTMLFormElement {
	hostname: HTMLInputElement;
	ipv4: HTMLInputElement;
	ipv6: HTMLInputElement;
}

const HostnameModal = () => {
	const dispatch = useAppDispatch();
	const [ modalVisible, setModalVisible ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);
	const createHostname = (hostname: Partial<IHostname>) => dispatch(createHostnameAction(hostname));

	const handleVisibilityChange = (newVisibility: boolean) => {
		setModalVisible(newVisibility);
		setSubmitted(false);
	};

	const handleModalSubmitted = (event: FormEvent<IHostnameFormElement>) => {
		event.preventDefault();
		setSubmitted(true);

		const hostname = event?.currentTarget?.hostname?.value;
		const ipv4 = event?.currentTarget?.ipv4?.value;
		const ipv6 = event?.currentTarget?.ipv6?.value;

		if (!hostname || !(ipv4 || ipv6)) {
			notify(NotificationType.ERROR, "A hostname and IPv4 or IPv6 address is required.");

			return;
		}

		createHostname({
			hostname,
			ipv4,
			ipv6
		});
	};

	const modalTitle = (
		<Fragment>
			<Icon
				name="server"
				fixedWidth
				iconWeight={ IconWeight.LIGHT }
			/>
			<span>Add Hostname</span>
		</Fragment>
	);

	const modalContent = (
		<form
			id="hostame-form"
			onSubmit={ handleModalSubmitted }
			className="hostname-form"
			noValidate
		>
			<div className="label">Hostname</div>
			<Input name="hostname" required noValidate={ !submitted } />
			<div className="label">IPv4</div>
			<Input name="ipv4" noValidate={ !submitted } />
			<div className="label">IPv6</div>
			<Input name="ipv6" noValidate={ !submitted } />
			<Button htmlType="submit" className="action-button">Submit</Button>
		</form>
	);

	return (
		<Modal
			title={ modalTitle }
			modalContent={ modalContent }
			visible={ modalVisible }
			onVisibilityChange={ handleVisibilityChange }
			className="hostname-modal"
		>
			<Button>Add Hostname</Button>
		</Modal>
	);
};

export default memo(HostnameModal);
