import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import {
	ILoginUserPayload, IRegisterUserPayload, IUpdatePasswordPayload, IUpdateUserPayload, IUser, IWithApiData
} from "Interfaces";
import { toggleFeatureRenderer as toggleFeature } from "Components/Features";
import * as UserApi from "Data/API/User";
import * as AuthApi from "Data/API/Auth";
import { Features } from "Data/Utils/Features";
import { deserializeJsonApiData } from "Data/Utils/JsonApi";

const {
	LOGOUT_USER, LOGIN_USER_ASYNC, UPDATE_ACCOUNT, DELETE_ACCOUNT, UPDATE_PASSWORD, REGISTER_USER
} = ACTION_TYPES.User;

export const loginUserAsyncAction = createAsyncThunk(
	LOGIN_USER_ASYNC,
	async (payload: ILoginUserPayload) => {
		return AuthApi.loginUser(payload);
	}
);

export const registerUserAction = createAsyncThunk(
	REGISTER_USER,
	async (payload: IRegisterUserPayload) => {
		return AuthApi.registerUser(payload);
	},
	{
		condition: () => {
			return toggleFeature(Features.REGISTER, true, false);
		}
	}
);

export const logoutUserRemoteAction = createAsyncThunk(
	LOGOUT_USER,
	async () => {
		return;
	}
);

export const logoutUserLocalAction = createAction(logoutUserRemoteAction.fulfilled.type);

export const updateAccountPasswordAction = createAsyncThunk(
	UPDATE_PASSWORD,
	async (payload: IUpdatePasswordPayload) => {
		return UserApi.resetAccountPassword(payload);
	}
);

export const updateUserAccountAction = createAsyncThunk(
	UPDATE_ACCOUNT,
	async (payload: IUpdateUserPayload) => {
		return UserApi.updateAccount(payload);
	}
);

export const deleteUserAccountAction = createAsyncThunk(
	DELETE_ACCOUNT,
	async (payload: void, thunkApi) => {
		UserApi.deleteAccount().then(() => {
			thunkApi.dispatch(logoutUserLocalAction);
		});
	}
);

// #region Local Actions for Pusher

export const updateAccountLocalAction = createAsyncThunk(
	UPDATE_ACCOUNT,
	async (payload: IWithApiData<Partial<IUser>>) => {
		return deserializeJsonApiData<Partial<IUser>>(payload);
	}
);

// #endregion
