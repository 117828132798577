import React, { memo, useEffect, useState } from "react";
import { Button, ButtonTypes } from "@clintonelec/react-storybook";
import "Components/Auth/VerifyEmailPage/VerifyEmailPage.less";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "Data/API/Auth";
import AuthPage from "Components/Auth/AuthPage";

const VerifyEmailPage = () => {
	const params = useParams();
	const { id, emailHash } = params;
	const [ verificationMessage, setVerificationMessage ] = useState("Thank you for verifying your email.");
	const navigate = useNavigate();
	const urlParams = new URLSearchParams(window.location.search);
	const timestamp = urlParams.get("expires");
	const signatureHash = urlParams.get("signature");

	useEffect(() => {
		verifyEmail(id, emailHash, timestamp, signatureHash)
			.catch((error) => {
				setVerificationMessage(`Unable to verify email: ${ error }`);
			});
	}, [ emailHash, id, signatureHash, timestamp ]);

	const handleSubmit = () => {
		navigate("/login");
	};

	const verifyEmailForm = (
		<div className="verify-email-page">
			<div className="logo" />
			<div>{ verificationMessage }</div>
			<Button
				id="submit-button"
				className="submit-button"
				type={ ButtonTypes.PRIMARY }
				onClick={ handleSubmit }
			>
				Continue To Login
			</Button>
		</div>
	);

	return <AuthPage form={ verifyEmailForm } />;
};

export default memo(VerifyEmailPage);
