// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-bar-popover-wrap {
  align-items: center;
  display: flex;
}
.title-bar-popover-wrap .cec-popover-children-wrap:hover {
  color: #b6b8bc;
  cursor: pointer;
}
.title-bar-popover-wrap > div {
  align-items: center;
  display: flex;
}
.title-bar-popover-wrap > div > span {
  padding: 0 5px;
}
.title-bar-popover-wrap .title-bar-popover {
  background: white;
}
.title-bar-popover-wrap .title-bar-popover > div {
  display: flex;
  width: 200px;
}
.title-bar-popover-wrap .title-bar-popover > div > div {
  width: 100%;
}
.title-bar-popover-wrap .title-bar-popover .title-bar-content-wrapper .title-bar-popover-row {
  color: #2e333f;
  cursor: pointer;
  padding: 8px 0 4px;
}
.title-bar-popover-wrap .title-bar-popover .title-bar-content-wrapper .title-bar-popover-row:hover {
  color: #8e8e8e;
}
.title-bar-popover-wrap .title-bar-popover .title-bar-content-wrapper .title-bar-popover-row > span,
.title-bar-popover-wrap .title-bar-popover .title-bar-content-wrapper .title-bar-popover-row > a {
  color: inherit;
  padding-left: 10px;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TitleBarPopover/TitleBarPopover.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,aAAA;AADD;AAIE;EACC,cAAA;EACA,eAAA;AAFH;AALA;EAYE,mBAAA;EACA,aAAA;AAJF;AATA;EAgBG,cAAA;AAJH;AAZA;EAqBE,iBAAA;AANF;AAfA;EAwBG,aAAA;EACA,YAAA;AANH;AAnBA;EA6BG,WAAA;AAPH;AAtBA;EAkCI,cAAA;EACA,eAAA;EACA,kBAAA;AATJ;AAWI;EACC,cAAA;AATL;AA9BA;;EA4CK,cAAA;EACA,kBAAA;EACA,qBAAA;AAVL","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.title-bar-popover-wrap {\n\talign-items: center;\n\tdisplay: flex;\n\n\t.cec-popover-children-wrap {\n\t\t&:hover {\n\t\t\tcolor: @gray-0;\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t> div {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\n\t\t> span {\n\t\t\tpadding: 0 5px;\n\t\t}\n\t}\n\n\t.title-bar-popover {\n\t\tbackground: white;\n\n\t\t> div {\n\t\t\tdisplay: flex;\n\t\t\twidth: 200px;\n\t\t}\n\n\t\t> div > div {\n\t\t\twidth: 100%;\n\t\t}\n\n\t\t.title-bar-content-wrapper {\n\t\t\t.title-bar-popover-row {\n\t\t\t\tcolor: @gray-2;\n\t\t\t\tcursor: pointer;\n\t\t\t\tpadding: 8px 0 4px;\n\n\t\t\t\t&:hover {\n\t\t\t\t\tcolor: @gray-1;\n\t\t\t\t}\n\n\t\t\t\t> span,\n\t\t\t\t> a {\n\t\t\t\t\tcolor: inherit;\n\t\t\t\t\tpadding-left: 10px;\n\t\t\t\t\ttext-decoration: none;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
