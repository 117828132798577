import React, { memo } from "react";
import TitleBarPopover from "Components/TitleBarPopover";
import "Components/TitleBar/TitleBar.less";
import ClintonLogoSVG from "Images/Clinton_Logo.svg";
import { useNavigate } from "react-router-dom";

const TitleBar = () => {
	const navigate = useNavigate();
	const navigateToHome = () => navigate("");

	return (
		<div className="title-bar">
			<img src={ ClintonLogoSVG } className="title-bar-logo" onClick={ navigateToHome } />
			<TitleBarPopover />
		</div>
	);
};

export default memo(TitleBar);
