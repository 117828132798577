// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hostname-table-container {
  height: 350px;
  padding: 20px;
  width: 100%;
}
.hostname-table-container .table-header {
  display: flex;
  padding: 0 15px 20px;
}
.hostname-table-container .table-header .cec-input-wrapper {
  margin-left: auto;
  width: 25%;
}
.hostname-table-container .table-header .cec-button:not(:last-child) {
  margin-right: 10px;
}
.hostname-table-container .cec-table {
  box-shadow: rgba(0 0 0 / 10%) 0 6px 16px 0;
}
.hostname-table-container .cec-table .action-button {
  cursor: pointer;
}
.hostname-table-container .cec-table .action-button:not(:last-child) {
  margin-right: 5px;
}
.hostname-table-container .cec-table tr:not(:hover) .action-button {
  visibility: hidden;
}
.hostname-table-container .cec-table .row-field {
  align-items: center;
  display: flex;
  height: 32px;
  margin-left: 12px;
}
.hostname-table-container .cec-table tr .cec-input-wrapper {
  width: 75%;
}
.hostname-table-container .cec-table .cec-column-title {
  margin-left: 12px;
}
.hostname-table-container .cec-table .cec-table-cell {
  width: 460px;
}
.hostname-table-container .icon-error {
  color: #fd4445;
  margin-left: 12px;
}
.hostname-table-container .icon-success {
  color: #33B889;
  margin-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HostnameTable/HostnameTable.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,aAAA;EACA,WAAA;AADD;AAFA;EAME,aAAA;EACA,oBAAA;AADF;AANA;EAUG,iBAAA;EACA,UAAA;AADH;AAVA;EAeG,kBAAA;AAFH;AAbA;EAoBE,0CAAA;AAJF;AAhBA;EAuBG,eAAA;AAJH;AAMG;EACC,iBAAA;AAJJ;AAtBA;EAgCI,kBAAA;AAPJ;AAzBA;EAqCG,mBAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;AATH;AA/BA;EA4CG,UAAA;AAVH;AAlCA;EAgDG,iBAAA;AAXH;AArCA;EAoDG,YAAA;AAZH;AAxCA;EAyDE,cAAA;EACA,iBAAA;AAdF;AA5CA;EA8DE,cAAA;EACA,iBAAA;AAfF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.hostname-table-container {\n\theight: 350px;\n\tpadding: 20px;\n\twidth: 100%;\n\n\t.table-header {\n\t\tdisplay: flex;\n\t\tpadding: 0 15px 20px;\n\n\t\t.cec-input-wrapper {\n\t\t\tmargin-left: auto;\n\t\t\twidth: 25%;\n\t\t}\n\n\t\t.cec-button:not(:last-child) {\n\t\t\tmargin-right: 10px;\n\t\t}\n\t}\n\n\t.cec-table {\n\t\tbox-shadow: rgba(0 0 0 / 10%) 0 6px 16px 0;\n\n\t\t.action-button {\n\t\t\tcursor: pointer;\n\n\t\t\t&:not(:last-child) {\n\t\t\t\tmargin-right: 5px;\n\t\t\t}\n\t\t}\n\n\t\ttr:not(:hover) {\n\t\t\t.action-button {\n\t\t\t\tvisibility: hidden;\n\t\t\t}\n\t\t}\n\n\t\t.row-field {\n\t\t\talign-items: center;\n\t\t\tdisplay: flex;\n\t\t\theight: 32px;\n\t\t\tmargin-left: 12px;\n\t\t}\n\n\t\ttr .cec-input-wrapper {\n\t\t\twidth: 75%;\n\t\t}\n\n\t\t.cec-column-title {\n\t\t\tmargin-left: 12px;\n\t\t}\n\n\t\t.cec-table-cell {\n\t\t\twidth: 460px;\n\t\t}\n\t}\n\n\t.icon-error {\n\t\tcolor: @error;\n\t\tmargin-left: 12px;\n\t}\n\n\t.icon-success {\n\t\tcolor: @success;\n\t\tmargin-left: 12px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
