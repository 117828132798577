import "Components/Global/PolicyModal/PolicyModal.less";
import { Modal } from "@clintonelec/react-storybook";
import React, { Fragment, memo } from "react";
import PrivacyPolicy from "Data/Objects/PrivacyPolicy";
import TermsAndConditions from "Data/Objects/TermsAndConditions";
import { ISection, ISubsection, ITerm, PolicyTermType, PolicyType } from "Interfaces";

interface IPolicyModalProps {
	children?: React.ReactNode;
	policy: PolicyType;
}

const PolicyModal = (props: IPolicyModalProps) => {
	const { children, policy } = props;
	const terms = policy === PolicyType.TERMS_AND_CONDITIONS ? TermsAndConditions : PrivacyPolicy;
	const renderTermDataItem = (item: string) => (
		<li key={ item }>{ item }</li>
	);

	const renderTerm = (term: ITerm, index: number) => {
		if (term.type === PolicyTermType.BULLETS) {
			return (
				<ul key={ `${ term.type }_${ index }` }>
					{ (term.data as string[]).map(renderTermDataItem) }
				</ul>
			);
		}

		return (
			<p key={ `${ term.data }_${ index }` } className="term">
				{ term.data }
			</p>
		);
	};

	const renderSubsection = (subsection: ISubsection) => (
		<Fragment key={ subsection.subtitle }>
			<h2>{ subsection.subtitle }</h2>
			{ subsection.data.map(renderTerm) }
		</Fragment>
	);

	const renderSection = (section: ISection) => (
		<Fragment key={ section.title }>
			<h1>{ section.title }</h1>
			{ section.data.map(renderSubsection) }
		</Fragment>
	);

	const modalContent = (
		<div className="container">
			{ terms.map(renderSection) }
		</div>
	);

	return (
		<Modal
			modalContent={ modalContent }
			width={ 800 }
			className="policy-modal"
		>
			<div>{ children }</div>
		</Modal>
	);
};

export default memo(PolicyModal);
