import { Button, ButtonTypes, ConfirmModal, IIcon, Input, Collapse, Panel } from "@clintonelec/react-storybook";
import React, { FormEvent, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { getActiveUser } from "Data/Selectors/User";
import { IUpdatePasswordPayload, IUpdateUserPayload } from "Interfaces";
import { updateUserAccountAction, deleteUserAccountAction, updateAccountPasswordAction } from "Data/Actions/User";
import { NotificationType, notify } from "src/Notifications";
import Icon from "Components/Icon";
import { escapeRegExp } from "lodash";
import "Components/UserSettings/UserSettings.less";

interface IAccountFormFields extends HTMLFormElement {
	email: HTMLInputElement;
	userName: HTMLInputElement;
}

interface IPasswordFormFields extends HTMLFormElement {
	currentPassword: HTMLInputElement;
	newPassword: HTMLInputElement;
	confirmNewPassword: HTMLInputElement;
}

const UserSettings = () => {
	const dispatch = useAppDispatch();
	const [ submitted, setSubmitted ] = useState<boolean>(false);
	const [ password, setPassword ] = useState<string>("");
	const accountForm = useRef<IAccountFormFields>(null);
	const passwordForm = useRef<IPasswordFormFields>(null);
	const { name, email } = useAppSelector(getActiveUser);
	const updateUserAccount = (user: IUpdateUserPayload) => dispatch(updateUserAccountAction(user));
	const changeAccountPassword = (payload: IUpdatePasswordPayload) => dispatch(updateAccountPasswordAction(payload));
	const deleteUserAccount = () => dispatch(deleteUserAccountAction);

	const handleAccountFormSubmit = (event: FormEvent<IAccountFormFields>) => {
		event.preventDefault();
		setSubmitted(true);

		if (accountForm.current?.checkValidity()) {
			const email = event.currentTarget.email?.value;
			const name = event.currentTarget.userName?.value;

			updateUserAccount({ email, name });
		}
	};

	const handleChangePasswordForm = (event: FormEvent<IPasswordFormFields>) => {
		event.preventDefault();
		setSubmitted(true);

		if (passwordForm.current?.checkValidity()) {
			const currentPassword = event.currentTarget.currentPassword?.value;
			const confirmNewPassword = event.currentTarget.confirmNewPassword?.value;

			changeAccountPassword({
				currentPassword,
				password: password,
				passwordConfirmation: confirmNewPassword
			}).unwrap().then(() => {
				notify(NotificationType.SUCCESS, "Successfully updated your password.");
			}, () => {
				notify(NotificationType.ERROR, "Unable to update your password.");
			});
		}
	};

	const deleteIcon: IIcon = {
		name: "trash"
	};

	return (
		<div className="account-page">
			<div className="settings-title">My Account</div>
			<Collapse className="settings-collapse">
				<Panel header="Update Basic Information" defaultCollapsed={ false }>
					<form
						className="account-form"
						ref={ accountForm }
						onSubmit={ handleAccountFormSubmit }
						noValidate
					>
						<label className="form-label">Email</label>
						<Input
							name="email"
							wrapClassName="form-inputs"
							defaultValue={ email }
							required
							noValidate={ !submitted }
							validityMessage="You must enter a valid email"
						/>
						<label className="form-label">Name</label>
						<Input
							name="userName"
							wrapClassName="form-inputs"
							defaultValue={ name }
							required
							noValidate={ !submitted }
						/>
						<div className="edit-account-button-wrap">
							<Button
								className="form-button"
								htmlType="submit"
								type={ ButtonTypes.PRIMARY }
							>
								Update Account
							</Button>
						</div>
					</form>
				</Panel>
			</Collapse>
			<Collapse className="settings-collapse">
				<Panel header="Change Password" defaultCollapsed={ false }>
					<form
						className="account-form"
						ref={ passwordForm }
						onSubmit={ handleChangePasswordForm }
						noValidate
					>
						<label className="form-label">Current Password</label>
						<Input
							name="currentPassword"
							password
							wrapClassName="form-inputs"
							onUpdate={ setPassword }
							validator={ "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$" }
							validityMessage="Your password must meet the requirements"
							noValidate={ !submitted }
							required
						/>
						<label className="form-label">New Password</label>
						<Input
							name="newPassword"
							password
							wrapClassName="form-inputs"
							onUpdate={ setPassword }
							validator={ "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$" }
							validityMessage="Your password must meet the requirements"
							noValidate={ !submitted }
							required
						/>
						<label className="form-label">Confirm New Password</label>
						<Input
							name="confirmNewPassword"
							password
							wrapClassName="form-inputs"
							validator={ `${ escapeRegExp(password) }` }
							validityMessage="Your passwords must match"
							noValidate={ !submitted }
							required
						/>
						<div className="edit-account-button-wrap">
							<Button
								className="form-button"
								htmlType="submit"
								type={ ButtonTypes.PRIMARY }
							>
								Change Password
							</Button>
						</div>
					</form>
				</Panel>
			</Collapse>
			<div className="delete-button-wrapper">
				<ConfirmModal
					title="Delete Account"
					icon={ <Icon name={ deleteIcon.name } className="danger-icon" /> }
					okButton={ { text: "Confirm", type: ButtonTypes.DANGER, onClick: deleteUserAccount } }
					cancelButton={ { text: "Cancel" } }
				>
					<Button
						className="form-button"
						type={ ButtonTypes.DANGER }
						icon={ deleteIcon }
					>
						Delete Account
					</Button>
				</ConfirmModal>
			</div>
		</div>
	);
};

export default UserSettings;
