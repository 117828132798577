// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Images/Clinton_Logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-password-page {
  top: 2vh;
  width: 100%;
}
.forgot-password-page .logo {
  background-image: radial-gradient(circle at 0 200px, rgba(0 211 255 / 100%) 0%, rgba(0 255 255 / 100%) 0%, rgba(0 113 186 / 100%) 55%);
  height: 5em;
  margin-bottom: 1vh;
  mask: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  padding: 10px;
  top: -2.75em;
  width: 100%;
}
.forgot-password-page .submit-button {
  margin-top: 2em;
  width: 100%;
}
.forgot-password-page .submit-button:hover,
.forgot-password-page .submit-button:focus {
  background-color: #68d5ae;
  border-color: #68d5ae;
  color: white;
}
.forgot-password-page .submit-button .icon {
  margin-right: 1em;
}
.forgot-password-page .verify-input-password {
  margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Auth/ForgotPasswordPage/ForgotPasswordPage.less"],"names":[],"mappings":"AAEA;EACC,QAAA;EACA,WAAA;AADD;AADA;EAKE,sIAAA;EAOA,WAAA;EACA,kBAAA;EACA,8DAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AAPF;AAVA;EAqBE,eAAA;EACA,WAAA;AARF;AAUE;;EAEC,yBAAA;EACA,qBAAA;EACA,YAAA;AARH;AApBA;EAgCG,iBAAA;AATH;AAvBA;EAqCE,kBAAA;AAXF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.forgot-password-page {\n\ttop: 2vh;\n\twidth: 100%;\n\n\t.logo {\n\t\tbackground-image:\n\t\t\tradial-gradient(\n\t\t\t\tcircle at 0 200px,\n\t\t\t\trgba(0 211 255 / 100%) 0%,\n\t\t\t\trgba(0 255 255 / 100%) 0%,\n\t\t\t\trgba(0 113 186 / 100%) 55%\n\t\t\t);\n\t\theight: 5em;\n\t\tmargin-bottom: 1vh;\n\t\tmask: url(\"../../../Images/Clinton_Logo.svg\") no-repeat center;\n\t\tpadding: 10px;\n\t\ttop: -2.75em;\n\t\twidth: 100%;\n\t}\n\n\t.submit-button {\n\t\tmargin-top: 2em;\n\t\twidth: 100%;\n\n\t\t&:hover,\n\t\t&:focus {\n\t\t\tbackground-color: @success-light;\n\t\t\tborder-color: @success-light;\n\t\t\tcolor: white;\n\t\t}\n\n\t\t.icon {\n\t\t\tmargin-right: 1em;\n\t\t}\n\t}\n\n\t.verify-input-password {\n\t\tmargin-bottom: 7px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
