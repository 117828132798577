// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-page {
  display: flex;
  flex-direction: column;
  padding: 40px 25% 0;
  width: 100%;
}
.account-page .settings-title {
  font-size: large;
  font-weight: bold;
  padding: 0 0 20px 10px;
}
.account-page .settings-collapse {
  border-top: 1px solid #b6b8bc;
}
.account-page .settings-collapse .cec-collapse-header {
  align-items: center;
  background: none;
  color: #8e8e8e;
  display: flex;
  height: 74px;
}
.account-page .settings-collapse .cec-collapse-header:hover {
  background: #f0f0f0;
}
.account-page .settings-collapse .cec-collapse-content .account-form {
  padding: 0 20px 20px;
}
.account-page .settings-collapse .cec-collapse-content .account-form .form-inputs {
  margin-bottom: 5px;
  width: 100%;
}
.account-page .settings-collapse .cec-collapse-content .account-form .form-button {
  margin-top: 20px;
}
.account-page .delete-button-wrapper {
  border-top: 1px solid #b6b8bc;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserSettings/UserSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AADD;AAHA;EAOE,gBAAA;EACA,iBAAA;EACA,sBAAA;AADF;AARA;EAaE,6BAAA;AAFF;AAXA;EAgBG,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;AAFH;AAIG;EACC,mBAAA;AAFJ;AArBA;EA4BG,oBAAA;AAJH;AAxBA;EA+BI,kBAAA;EACA,WAAA;AAJJ;AA5BA;EAoCI,gBAAA;AALJ;AA/BA;EA0CE,6BAAA;EACA,aAAA;EACA,OAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;AARF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.account-page {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 40px 25% 0;\n\twidth: 100%;\n\n\t.settings-title {\n\t\tfont-size: large;\n\t\tfont-weight: bold;\n\t\tpadding: 0 0 20px 10px;\n\t}\n\n\t.settings-collapse {\n\t\tborder-top: 1px solid @gray-0;\n\n\t\t.cec-collapse-header {\n\t\t\talign-items: center;\n\t\t\tbackground: none;\n\t\t\tcolor: @gray-1;\n\t\t\tdisplay: flex;\n\t\t\theight: 74px;\n\n\t\t\t&:hover {\n\t\t\t\tbackground: @light-gray-0;\n\t\t\t}\n\t\t}\n\n\t\t.cec-collapse-content .account-form {\n\t\t\tpadding: 0 20px 20px;\n\n\t\t\t.form-inputs {\n\t\t\t\tmargin-bottom: 5px;\n\t\t\t\twidth: 100%;\n\t\t\t}\n\n\t\t\t.form-button {\n\t\t\t\tmargin-top: 20px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.delete-button-wrapper {\n\t\tborder-top: 1px solid @gray-0;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tjustify-content: flex-end;\n\t\tpadding: 20px;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
