import { store } from "Data/Redux/Store";
import { IErrorResult, IWithApiData } from "Interfaces";
import { IIndexSignature } from "@clintonelec/typescriptutils";
import { NotificationType, notify } from "src/Notifications";
import { isProduction } from "Data/Utils/System";

type RequestType = "DELETE" | "GET" | "POST" | "PATCH";

const baseUrl = isProduction
	? "https://ddns.clintonelectronics.com/api"
	: "https://develop.ddns.clintonelectronics.com/api";

const baseHeaders = {
	"Content-Type": "application/vnd.api+json",
	"Accept": "application/vnd.api+json"
};

export function GET(path: string, params?: IIndexSignature<string | number>) {
	return request(path, "GET", null, null, params);
}

export function POST(path: string, body?: unknown) {
	return request(path, "POST", JSON.stringify(body), null, null);
}

export function PATCH(path: string, body?: unknown) {
	return request(path, "PATCH", JSON.stringify(body), null, null);
}

export function DELETE(path: string, params?: IIndexSignature<string | number>, body?: unknown) {
	return request(path, "DELETE", JSON.stringify(body), null, params);
}

function request(path: string, method: RequestType, body?: string, headers?: { [key: string]: string },
	query?: IIndexSignature<string | number>) {

	let url = `${ baseUrl }/${ path }`;

	if (query && Object.keys(query).length != 0) {
		url += `?${ encodeQueryParams(query) }`;
	}

	const { User: { token } } = store.getState();
	const authHeaders = { "Authorization": `Bearer ${ token }` };

	return new Promise<IWithApiData<unknown>>((resolve, reject) => {
		return fetch(url, {
			body,
			headers: { ...baseHeaders, ...authHeaders, ...headers },
			method,
			mode: "cors"
		})
			.then((response: Response) => {
				const { status } = response;

				if (status >= 200 && status < 300) {
					if (status === 204) {
						return resolve({ data: undefined });
					}

					resolve(response.json());
				} else if (status >= 400 && status < 500) {
					if (status === 401 || status === 429) {
						reject(status);
					} else {
						response.json().then((errorResult: IErrorResult) => {
							reject(flattenErrors(errorResult));
						});
					}
				} else if (status >= 500 && status < 600) {
					notify(NotificationType.ERROR, "Error", "An unknown error has occurred");
					reject();
				}
			}, (error) => {
				console.log(error);
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

function encodeQueryParams(params: IIndexSignature<string | number>) {
	return Object.keys(params)
		.map((key) => {
			const encKey = encodeURIComponent(key);
			const encParam = encodeURIComponent(params[key]);

			return `${ encKey }=${ encParam }`;
		})
		.join("&");
}

const flattenErrors = (errorResult: IErrorResult) => {
	const { errors } = errorResult;
	const retVal = errors.map((error) => {
		return error.detail;
	}).reduce((messages: string[], currentMessage: string) => {
		return messages.concat(currentMessage);
	}, []);

	return retVal.join("\n");
};
