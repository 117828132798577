import { IWithDeserializedData, IWithId } from "Interfaces";

export interface IApiUser extends IUser, IWithDeserializedData { }

export interface IUser extends IWithId {
	name: string;
	email: string;
	verified: boolean;
}

export interface ILoginUserResponse {
	user: IUser;
	token: string;
	pusherKey: string;
}

export interface ILoginUserPayload {
	email: string;
	password: string;
}

export interface IRegisterUserPayload extends ILoginUserPayload {
	name: string;
	passwordConfirmation: string;
}

export interface IUpdateUserPayload {
	email: string;
	name: string;
}

export interface IUpdatePasswordPayload {
	currentPassword: string;
	password: string;
	passwordConfirmation: string;
}

export enum UserMeta {
	TOKEN = "token",
	PUSHER_KEY = "pusherKey"
}
