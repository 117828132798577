import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { loginUserAsyncAction, logoutUserRemoteAction, updateUserAccountAction } from "Data/Actions/User";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { handleClearCache } from "Data/Utils/Redux";
import { ILoginUserResponse, IUser, IUserState } from "Interfaces";

const { CLEAR_CACHE } = ACTION_TYPES.System;
const defaultState: IUserState = {
	pusherKey: "",
	user: null,
	token: null
};

export const loginUserReducer = (state: IUserState, action: PayloadAction<ILoginUserResponse>) => {
	const { pusherKey, user, token } = action?.payload ?? {};

	if (!token) {
		return;
	}

	state.pusherKey = pusherKey;
	state.user = user;
	state.token = token;
};

export const logoutUserReducer = (state: IUserState) => {
	state.user = null;
	state.token = null;
};

export const updateAccountReducer = (state: IUserState, action: PayloadAction<IUser>) => {
	if (!action?.payload) {
		return;
	}

	state.user = action.payload;
};

const reducers = (reducerBuilder) => {
	reducerBuilder.addCase(CLEAR_CACHE, handleClearCache(defaultState));
	reducerBuilder.addCase(loginUserAsyncAction.fulfilled, loginUserReducer);
	reducerBuilder.addCase(logoutUserRemoteAction.fulfilled, logoutUserReducer);
	reducerBuilder.addCase(updateUserAccountAction.fulfilled, updateAccountReducer);
};

export default createReducer(defaultState, reducers);
