import { createSelector } from "@reduxjs/toolkit";
import { filterPaginatedDataByUuid, getPaginatedData, getPaginatedDataOptions } from "Data/Utils/Redux";
import { IAppState, IHostname } from "Interfaces";
import { createIdentitySelector } from "@clintonelec/typescriptutils";

const getHostnamesState = (state: IAppState) => {
	return state.Hostnames;
};

export const getAllHostnames = createSelector(
	getHostnamesState,
	getPaginatedData
);

export const getHostnameById = createSelector(
	getHostnamesState,
	createIdentitySelector<IAppState, string>(),
	filterPaginatedDataByUuid<IHostname>
);

export const getHostnamesOptions = createSelector(
	getHostnamesState,
	getPaginatedDataOptions
);
