import React, { FormEvent, memo, useRef, useState } from "react";
import { Button, ButtonTypes, Input } from "@clintonelec/react-storybook";
import "Components/Auth/ForgotPasswordPage/ForgotPasswordPage.less";
import { NotificationType, notify } from "src/Notifications";
import { resetPassword } from "Data/API/Auth";
import { IResetPasswordPayload } from "Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { escapeRegExp } from "lodash";
import { IconSize } from "Components/Icon";

interface IResetPasswordFormFields extends HTMLFormElement {
	password: HTMLInputElement;
	passwordConfirmation: HTMLInputElement;
}

const ForgotPasswordPage = () => {
	const [ password, setPassword ] = useState("");
	const [ submitted, setSubmitted ] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);
	const params = useParams();
	const { token } = params;
	const navigate = useNavigate();

	const onSubmit = (event: FormEvent<IResetPasswordFormFields>) => {
		event.preventDefault();

		setSubmitted(true);

		const urlParams = new URLSearchParams(window.location.search);
		const payload: IResetPasswordPayload = {
			email: urlParams.get("email"),
			password: event?.currentTarget?.password?.value,
			passwordConfirmation: event?.currentTarget?.passwordConfirmation?.value,
			token: token
		};

		if (formRef.current.checkValidity()) {
			resetPassword(payload)
				.then(() => {
					notify(NotificationType.SUCCESS, "Password Changed Successfully");

					navigate("/");
				})
				.catch((error) => {
					notify(NotificationType.ERROR, "Error Changing Password", error);
				});
		}
	};

	return (
		<div className="forgot-password-page">
			<div className="logo" />
			<form onSubmit={ onSubmit } ref={ formRef } noValidate>
				<label>New Password</label>
				<Input
					name="password"
					password
					wrapClassName="verify-input-password"
					required
					onUpdate={ setPassword }
					noValidate={ !submitted }
					validityMessage={ "Password is required" }
				/>
				<label>Confirm New Password</label>
				<Input
					name="passwordConfirmation"
					password
					required
					validator={ `^${ escapeRegExp(password) }$` }
					noValidate={ !submitted }
					validityMessage={ "Password must match" }
				/>
				<Button
					id="submit-button"
					className="submit-button"
					type={ ButtonTypes.PRIMARY }
					htmlType="submit"
					icon={ {
						name: "lock-keyhole",
						size: IconSize.SMALLER
					} }
				>
					Reset Password
				</Button>
			</form>
		</div>
	);
};

export default memo(ForgotPasswordPage);
